import React from "react";
import "./LandingPage.css";

const LandingPage = () => {
  return (
    <>
      <div className="caddie-landing">
        <h1 className="caddie-heading">Caddie</h1>
      </div>
    </>
  );
};

export default LandingPage;
